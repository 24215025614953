import React from 'react';
import { Input, Form, Select, InputNumber } from 'antd';
import { TimeCodeType, TimeCodeTypeLabels } from '../../atoms/video';
import { MarkerImg } from './MarkerImg';

interface InputTextProps {
  name: string;
  label?: string;
  title?: string;
  style?: React.CSSProperties;
}
export const InputText = ({
  name,
  label,
  title = '',
  style,
}: InputTextProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      style={style}
      rules={[
        {
          required: true,
          message: `Please Input ${label || title}!`,
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
};

interface InputNumberFieldProps {
  name: string;
  label?: string;
  title?: string;
  style?: React.CSSProperties;
  min?: number;
  max?: number;
  step?: number;
}

export const InputNumberField = ({
  name,
  label,
  title = '',
  style,
  min,
  max,
  step = 1,
}: InputNumberFieldProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      style={style}
      rules={[
        {
          required: true,
          message: `Please Input ${label || title}!`,
        },
      ]}
    >
      <InputNumber min={min} max={max} step={step} style={{ width: '100%' }} />
    </Form.Item>
  );
};

const timeCodeTypeOptions = Object.entries(TimeCodeTypeLabels).map(
  ([value, label]) => {
    return {
      value,
      label: (
        <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <MarkerImg type={value as TimeCodeType} />
          {label}
        </span>
      ),
    };
  },
);

export const TimecodeTypeSelect = ({
  name,
  label,
}: {
  name: string;
  label?: string;
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      style={{ margin: 0, minWidth: '150px' }}
    >
      <Select options={timeCodeTypeOptions} />
    </Form.Item>
  );
};
