import { loadedObjectsState, LoadedObjects } from '../atoms/loadedObjects';
import { useRecoilState } from 'recoil';

export const useLoadedObjects = () => {
  const [loadedObjects, setLoadedObjects] = useRecoilState(loadedObjectsState);

  const setKmzLoaded = (loaded: boolean) => {
    setLoadedObjects((prev) => ({ ...prev, kmz: loaded }));
  };

  const setTilesLoaded = (loaded: boolean) => {
    setLoadedObjects((prev) => ({ ...prev, tiles: loaded }));
  };

  const setTlogLoaded = (loaded: boolean) => {
    setLoadedObjects((prev) => ({ ...prev, tlog: loaded }));
  };

  const clearAllObjects = () => {
    setLoadedObjects({ kmz: false, tiles: false, tlog: false });
  };

  const clearTlogObjects = () => {
    setLoadedObjects((prev) => ({ ...prev, tlog: false }));
  };

  const clearEditorObjects = () => {
    setLoadedObjects((prev) => ({ ...prev, tlog: false }));
  };

  return {
    loadedObjects,
    setKmzLoaded,
    setTilesLoaded,
    setTlogLoaded,
    clearAllObjects,
    clearTlogObjects,
    clearEditorObjects,
  };
};
