import { theme as antdTheme } from 'antd';
import { ThemeType } from '../atoms/theme';
import { colors } from './colors';

/**
 * Returns theme configuration for Ant Design components
 *
 * This function creates a theme configuration object used in ConfigProvider
 * to style all Ant Design components according to the selected theme (light or dark).
 *
 * Configuration structure:
 * 1. algorithm - theme algorithm (light or dark)
 * 2. token - base theme tokens that affect all components
 * 3. components - specific settings for individual components
 *
 * @param themeName - 'light' or 'dark'
 * @returns Theme configuration object for Ant Design
 */
export const getThemeConfig = (themeName: ThemeType) => {
  const isDark = themeName === 'dark';
  const themeColors = isDark ? colors.dark : colors.light;
  const white = '#ffffff';
  const black = '#111827';

  return {
    algorithm: isDark ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm,
    token: {
      // Base colors
      colorBgBase: themeColors.background,
      colorTextBase: themeColors.text,
      colorBorder: themeColors.border,
      colorBgContainer: themeColors.background,
      colorBgElevated: themeColors.secondaryBackground,
      colorBgLayout: themeColors.secondaryBackground,

      // Fill colors
      colorFillContent: themeColors.border,
      colorFillContentHover: isDark ? '#4b5563' : '#e5e7eb',
      colorFillAlter: themeColors.border,
      colorBgMask: colors.common.mask,

      // Functional colors
      colorPrimary: colors.common.primary,
      colorPrimaryHover: colors.common.primaryHover,
      colorSuccess: colors.common.success,
      colorWarning: colors.common.warning,
      colorError: colors.common.error,
    },
    components: {
      // Modal settings
      Modal: {
        colorText: themeColors.text,
        colorBgContainer: themeColors.background,
        colorBgElevated: themeColors.background,
        contentBg: themeColors.background,
        headerBg: themeColors.background,
        titleColor: themeColors.text,
        colorIcon: themeColors.text,
      },

      // Table settings
      Table: {
        colorBgContainer: themeColors.background,
        colorText: themeColors.text,
        colorFillAlter: themeColors.border,
        headerBg: themeColors.secondaryBackground,
        headerColor: themeColors.text,
        headerSplitColor: themeColors.border,
        rowHoverBg: themeColors.secondaryBackground,
        colorBorderSecondary: themeColors.border,
        // Empty component settings inside table
        emptyText: themeColors.text,
        emptyTextColor: themeColors.text,
      },

      // Form settings
      Form: {
        colorText: themeColors.text,
        colorTextLabel: themeColors.text,
        labelColor: themeColors.text,
      },

      // Input settings
      Input: {
        colorText: themeColors.text,
        colorBgContainer: themeColors.background,
        colorBorder: themeColors.border,
        activeBorderColor: colors.common.primary,
        hoverBorderColor: colors.common.primary,
        colorBgContainerDisabled: themeColors.secondaryBackground,
        colorTextDisabled: isDark
          ? 'rgba(255, 255, 255, 0.25)'
          : 'rgba(0, 0, 0, 0.25)',
        addonBg: themeColors.secondaryBackground,
        outlinedBg: themeColors.background,
        outlinedActiveBorderColor: colors.common.primary,
        outlinedHoverBorderColor: colors.common.primary,
        outlinedBorderColor: themeColors.border,
      },

      // InputNumber settings
      InputNumber: {
        colorText: themeColors.text,
        colorBgContainer: themeColors.background,
        colorBorder: themeColors.border,
        activeBorderColor: colors.common.primary,
        hoverBorderColor: colors.common.primary,
        handleColor: themeColors.text,
        handleBg: themeColors.background,
      },

      // Select settings
      Select: {
        colorText: themeColors.text,
        colorBgContainer: themeColors.background,
        colorBorder: themeColors.border,
        colorBgElevated: themeColors.background,
        colorItemBgSelected: themeColors.secondaryBackground,
        optionSelectedBg: themeColors.secondaryBackground,
        optionActiveBg: themeColors.secondaryBackground,
        selectorBg: themeColors.background,
      },

      // Button settings
      Button: {
        colorText: themeColors.text,
        colorBgContainer: themeColors.background,
        colorBorder: themeColors.border,
        primaryColor: white,
        primaryBg: isDark ? colors.common.primary : black,
        defaultBg: themeColors.background,
        defaultColor: themeColors.text,
        defaultBorderColor: themeColors.border,
        // Additional button settings
        colorPrimaryText: white,
        colorPrimaryBg: isDark ? colors.common.primary : black,
        colorPrimaryBgHover: isDark ? colors.common.primaryHover : '#232b34',
        colorPrimaryBorder: isDark ? colors.common.primary : black,
        colorPrimaryBorderHover: isDark
          ? colors.common.primaryHover
          : '#232b34',
        colorPrimaryActive: isDark ? colors.common.primaryHover : '#232b34',
        // Success button settings
        colorSuccessBg: colors.common.success,
        colorSuccessBgHover: colors.common.successHover,
        colorSuccessBorder: colors.common.success,
        colorSuccessBorderHover: colors.common.successHover,
        colorSuccessText: white,
        // Error button settings
        colorErrorBg: colors.common.error,
        colorErrorBgHover: colors.common.errorHover,
        colorErrorBorder: colors.common.error,
        colorErrorBorderHover: colors.common.errorHover,
        colorErrorText: white,
      },

      // Switch settings
      Switch: {
        colorPrimary: colors.common.primary,
        colorPrimaryHover: colors.common.primaryHover,
        colorTextQuaternary: 'rgba(0, 0, 0, 0.3)',
        colorTextTertiary: 'rgba(0, 0, 0, 0.6)',
        colorText: white,
        color: white,
        colorBgContainer: isDark ? themeColors.border : colors.light.border,
        handleBg: white,
        handleShadow: 'rgba(0, 0, 0, 0.2)',
        loadingColor: white,
        colorPrimaryBorder: colors.common.primary,
      },

      // Drawer settings
      Drawer: {
        colorBgElevated: themeColors.background,
        colorText: themeColors.text,
        colorBgContainer: themeColors.background,
        colorBorder: themeColors.border,
        headerBg: themeColors.background,
        titleColor: themeColors.text,
        colorIcon: themeColors.text,
      },

      // Skeleton settings
      Skeleton: {
        colorFill: themeColors.secondaryBackground,
        colorFillContent: themeColors.border,
      },

      // Pagination settings
      Pagination: {
        colorText: themeColors.text,
        colorPrimary: colors.common.primary,
      },

      // Popover settings
      Popover: {
        colorBgElevated: themeColors.background,
        colorText: themeColors.text,
      },

      // Tooltip settings
      Tooltip: {
        colorBgDefault: isDark ? themeColors.secondaryBackground : black,
        colorTextLightSolid: isDark ? themeColors.text : white,
        colorBgElevated: isDark ? themeColors.secondaryBackground : black,
        colorText: isDark ? themeColors.text : white,
        colorBorder: isDark ? themeColors.border : 'transparent',
      },

      // Typography settings
      Typography: {
        colorText: themeColors.text,
        colorTextSecondary: isDark ? '#d1d5db' : '#6b7280',
      },

      // Card settings
      Card: {
        colorBgContainer: themeColors.background,
        colorBorderSecondary: themeColors.border,
        colorText: themeColors.text,
        headerBg: themeColors.background,
        headerBorderColor: themeColors.border,
        actionsBg: themeColors.background,
      },

      // Tabs settings
      Tabs: {
        cardBg: themeColors.background,
        cardGutter: 2,
        itemSelectedColor: colors.common.primary,
        itemHoverColor: colors.common.primaryHover,
        inkBarColor: colors.common.primary,
        titleFontSize: 14,
      },

      // Empty settings
      Empty: {
        colorText: themeColors.text,
        colorTextDisabled: isDark ? '#9ca3af' : '#9ca3af',
        colorFill: themeColors.secondaryBackground,
        colorFillSecondary: themeColors.border,
        colorFillQuaternary: themeColors.border,
      },

      // Message settings
      Message: {
        colorText: themeColors.text,
        colorBgBase: themeColors.background,
        colorBgElevated: themeColors.background,
        colorBorder: themeColors.border,
        colorIcon: themeColors.text,
        colorSuccess: colors.common.success,
        colorError: colors.common.error,
        colorWarning: colors.common.warning,
        colorInfo: colors.common.primary,
      },

      // Notification settings
      Notification: {
        colorText: themeColors.text,
        colorBgElevated: themeColors.background,
        colorBgBase: themeColors.background,
        colorBorder: themeColors.border,
        colorIcon: themeColors.text,
        colorSuccess: colors.common.success,
        colorError: colors.common.error,
        colorWarning: colors.common.warning,
        colorInfo: colors.common.primary,
        colorBg: themeColors.background,
        colorTextHeading: themeColors.text,
        colorTextDescription: themeColors.text,
        headerBg: themeColors.background,
        headerColor: themeColors.text,
        closeBtnHoverBg: themeColors.secondaryBackground,
      },

      // Alert settings
      Alert: {
        colorText: themeColors.text,
        colorSuccess: colors.common.success,
        colorSuccessBg: isDark
          ? 'rgba(82, 196, 26, 0.2)'
          : 'rgba(82, 196, 26, 0.1)',
        colorSuccessBorder: isDark
          ? 'rgba(82, 196, 26, 0.3)'
          : 'rgba(82, 196, 26, 0.2)',
        colorError: colors.common.error,
        colorErrorBg: isDark
          ? 'rgba(245, 34, 45, 0.2)'
          : 'rgba(245, 34, 45, 0.1)',
        colorErrorBorder: isDark
          ? 'rgba(245, 34, 45, 0.3)'
          : 'rgba(245, 34, 45, 0.2)',
        colorWarning: colors.common.warning,
        colorWarningBg: isDark
          ? 'rgba(250, 173, 20, 0.2)'
          : 'rgba(250, 173, 20, 0.1)',
        colorWarningBorder: isDark
          ? 'rgba(250, 173, 20, 0.3)'
          : 'rgba(250, 173, 20, 0.2)',
        colorInfo: colors.common.primary,
        colorInfoBg: isDark
          ? 'rgba(24, 144, 255, 0.2)'
          : 'rgba(24, 144, 255, 0.1)',
        colorInfoBorder: isDark
          ? 'rgba(24, 144, 255, 0.3)'
          : 'rgba(24, 144, 255, 0.2)',
      },
    },
  };
};
