import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import { useRecoilValue } from 'recoil';
import { themeState } from '../../atoms/theme';

import LogoImage from './logo.png';
import styles from './Logo.module.css';
import LogoWhite from '../LogoWhite';

interface LogoProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isLogin?: boolean;
}

export const Logo = ({ isLogin = false }: LogoProps) => {
  const theme = useRecoilValue(themeState);
  const isDarkTheme = theme === 'dark';

  return (
    <div
      className={cn(styles.containerLogo, {
        [styles.loginContainer]: isLogin,
      })}
    >
      {isDarkTheme ? (
        <LogoWhite
          style={{
            width: isLogin ? '47px' : '31px',
            height: isLogin ? '51px' : '34px',
          }}
        />
      ) : (
        <img
          src={LogoImage}
          alt="Logo"
          className={cn(styles.logo, {
            [styles.loginLogo]: isLogin,
          })}
        />
      )}
      <span
        className={cn(styles.logoText, {
          [styles.loginLogoText]: isLogin,
        })}
      >
        STRIBOG
      </span>
    </div>
  );
};
