import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import {
  GoogleMap,
  MarkerF,
  PolylineF,
  useJsApiLoader,
} from '@react-google-maps/api';
import './GMap.css';
import { Location, MapPosition, VideoInfo } from '../../atoms/video';
import { useMarkersIconsMap } from '../../hooks/useMarkersIconsMap';
import { debounce } from 'lodash';
import { MAVLink20Processor } from 'mavlink-browser';
import { ReactComponent as TlogIcon } from '../../atoms/icons/tlog.svg';
import { ReactComponent as NorthIcon } from '../../atoms/icons/north.svg';
import { ReactComponent as TrashIcon } from '../../atoms/icons/trash.svg';
import { useLoading } from '../../hooks/useLoading';
import { useLoadedObjects } from '../../hooks/useLoadedObjects';

const defaultContainerStyle = {
  width: '100%',
  height: '90vh',
  flexBasis: '100%',
  borderRadius: '10px',
};

const CHUNK_SIZE = 4064 * 1024; // Read 1Mb at a time
const MESSAGE_FILTER_INTERVAL = 1;

type MapContainerStyle = typeof defaultContainerStyle;

interface GPSPoint {
  lat: number;
  lng: number;
}

export const defaultCenter = {
  lat: 49.024502,
  lng: 31.419201,
};

interface LatLng {
  lat: number;
  lng: number;
}

export interface GMapProps {
  videoList: VideoInfo[];
  mapPosition?: MapPosition;
  activeSegment?: VideoInfo | null;
  mapStyles?: Partial<MapContainerStyle>;
  markerLocationUpdate?: (data: VideoInfo, location: Location) => void;
  handleMarkerClick?: (segment: VideoInfo) => void;
  handleMapClick?: (location: Location) => void;
  handleMapPositionUpdate?: (position: MapPosition) => void;
  carDragMarker?: (data: VideoInfo) => boolean;
}

const GMap: React.FC<GMapProps> = ({
  videoList,
  mapPosition,
  markerLocationUpdate,
  handleMarkerClick,
  activeSegment,
  mapStyles,
  handleMapClick,
  handleMapPositionUpdate,
  carDragMarker = () => true,
}) => {
  const [mapRef, setMapRefRef] = React.useState<google.maps.Map>();
  const [routePath, setRoutePath] = useState<LatLng[] | undefined>(undefined);
  const dataLayerRef = useRef<google.maps.Data.Feature[]>([]);
  const dataLayerClickRef = useRef<google.maps.MapsEventListener | null>();
  const [infoWindow] = useState<google.maps.InfoWindow | null>(null);
  const [gpsPoints, setGpsPoints] = useState<GPSPoint[]>([]);
  const { showLoading, hideLoading } = useLoading();
  const { getIconSrc } = useMarkersIconsMap();
  const { loadedObjects, setTlogLoaded, clearEditorObjects } =
    useLoadedObjects();

  const handleMapClickInternal = (event: google.maps.MapMouseEvent) => {
    const currentLocation = {
      lat: event!.latLng!.lat(),
      lng: event!.latLng!.lng(),
    };

    handleMapClick?.(currentLocation);
  };

  const handleMarkerClickInternal = (segment: VideoInfo) => {
    handleMarkerClick?.(segment);
  };

  const handlerMarkerDrag = (
    event: google.maps.MapMouseEvent,
    segment: VideoInfo,
  ) => {
    const newLocation: Location = {
      lat: event!.latLng!.lat(),
      lng: event!.latLng!.lng(),
    };
    markerLocationUpdate?.(segment, newLocation);
  };

  const handlePositionChanged = debounce(() => {
    const center = mapRef?.getCenter();
    const zoom = mapRef?.getZoom();
    if (!zoom || !center) return;

    const location = { lat: center.lat(), lng: center.lng() };
    handleMapPositionUpdate?.({ location, zoom });
  }, 50);

  useEffect(() => {
    setRoutePath(videoList.map((segment) => segment.location as LatLng));
  }, [videoList]);

  useEffect(() => {
    const loc = mapPosition
      ? mapPosition.location
      : activeSegment
        ? activeSegment.location
        : defaultCenter;
    if (loc) {
      mapRef?.setCenter(loc);
    }
  }, [activeSegment, mapPosition]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  useEffect(() => {
    if (!isLoaded) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoaded, showLoading, hideLoading]);

  if (!isLoaded) return null;

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    showLoading();
    const file = e.target.files?.[0];
    if (!file) {
      hideLoading();
      return;
    }

    const reader = new FileReader();
    const processor = new MAVLink20Processor(null, null, null);
    const filteredGpsPoints: GPSPoint[] = [];
    let offset = 0;
    let messageCount = 0;

    const readChunk = () => {
      const chunk = file.slice(offset, offset + CHUNK_SIZE);
      reader.readAsArrayBuffer(chunk);
    };

    reader.onload = (event) => {
      if (!event.target?.result) return;
      let data = new Uint8Array(event.target.result as ArrayBuffer);

      // Parse buffer chunk and filter messages
      const messages = processor.parseBuffer(data);

      messages.forEach((msg) => {
        messageCount++;
        if (messageCount % MESSAGE_FILTER_INTERVAL === 0) {
          if (msg._name === 'GLOBAL_POSITION_INT') {
            const { lat, lon } = msg;
            filteredGpsPoints.push({
              lat: lat / 1e7,
              lng: lon / 1e7,
            });
          }
        }
      });

      // Continue reading next chunk if available
      offset += CHUNK_SIZE;
      if (offset < file.size) {
        readChunk();
      } else {
        console.log('File processing complete ' + filteredGpsPoints.length);
        setGpsPoints(filteredGpsPoints);
        setTlogLoaded(true);
        data = new Uint8Array(0);
        hideLoading();
      }
    };

    reader.onerror = (err) => {
      console.error('Error reading file:', err);
      hideLoading();

      // Clear input value to allow loading the same file again if there was an error
      if (e.target) {
        e.target.value = '';
      }
    };

    // Start reading the first chunk
    readChunk();
  };

  const handleSetNorth = () => {
    mapRef?.setHeading(0);
    mapRef?.setTilt(0);

    // Clear North button input to allow clicking again
    const northButton = document.getElementById('North') as HTMLButtonElement;
    if (northButton) {
      northButton.blur();
    }
  };

  const handleClearObjects = () => {
    // Clear GPS points
    setGpsPoints([]);

    // Clear loaded objects state
    clearEditorObjects();

    // Clear input value to allow loading the same file again
    const fileInput = document.getElementById('Tlog') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <div className="map_container">
      <div>
        <label htmlFor="Tlog">
          <TlogIcon
            style={{
              position: 'absolute',
              top: '175px',
              right: '15px',
              zIndex: 1000,
              width: '35px',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            fill={loadedObjects.tlog ? 'grey' : 'white'}
          />
        </label>
        <input
          id={'Tlog'}
          type="file"
          onChange={handleFileUpload}
          style={{
            display: 'none',
            position: 'fixed',
            pointerEvents: 'auto',
            zIndex: 1000,
          }}
        />
        <label htmlFor="North">
          <NorthIcon
            style={{
              position: 'absolute',
              top: '135px',
              right: '15px',
              zIndex: 1000,
              width: '35px',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            fill="black"
            stroke="white"
          />
        </label>
        <button
          id={'North'}
          onClick={handleSetNorth}
          style={{
            display: 'none',
            position: 'fixed',
            pointerEvents: 'auto',
            zIndex: 1000,
          }}
        />
        <TrashIcon
          id="trashIconEditor"
          onClick={handleClearObjects}
          style={{
            position: 'absolute',
            top: '215px',
            right: '15px',
            zIndex: 1000,
            width: '35px',
            border: 'none',
            padding: '10px 15px',
            borderRadius: '5px',
            cursor: 'pointer',
            display: loadedObjects.tlog ? 'flex' : 'none',
            alignItems: 'center',
          }}
          fill="white"
        />
        <GoogleMap
          onCenterChanged={handlePositionChanged}
          onLoad={(map) => {
            setMapRefRef(map);
            map.addListener('click', handleMapClickInternal);
            map.setCenter(
              mapPosition
                ? mapPosition.location
                : activeSegment
                  ? activeSegment.location
                  : defaultCenter,
            );
            if (mapPosition?.zoom) {
              map.setZoom(mapPosition.zoom);
            }
          }}
          mapContainerStyle={{ ...defaultContainerStyle, ...mapStyles }}
          options={{
            mapId: '90f87356969d889c',
            mapTypeId: 'hybrid',
            gestureHandling: 'greedy',
            zoom: mapRef?.getZoom() || 6,
            tilt: mapRef?.getTilt() || 0,
          }}
        >
          {videoList.map((segment, index) => (
            <PolylineF
              key={index}
              path={routePath}
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                icons: [
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    },
                    offset: '100%',
                    repeat: '30%',
                  },
                ],
              }}
            />
          ))}
          {videoList.map((segment, index) => {
            const url = (() => {
              const isCurrentVideo =
                activeSegment && activeSegment.fileName === segment.fileName;
              const isCurrentSegment = activeSegment === segment;
              if (segment.type) {
                const iconStyle = isCurrentVideo
                  ? isCurrentSegment
                    ? 'active'
                    : 'otherSegment'
                  : 'default';
                return getIconSrc(segment.type, iconStyle);
              }

              // default markers
              return isCurrentVideo
                ? isCurrentSegment
                  ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                  : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
            })();

            const isCurrentVideo =
              activeSegment && activeSegment.fileName === segment.fileName;
            const isCurrentSegment = activeSegment === segment;

            const zIndex = isCurrentVideo ? (isCurrentSegment ? 1000 : 1) : 1;

            const scaledSize = isCurrentVideo
              ? isCurrentSegment
                ? new google.maps.Size(38, 38)
                : new google.maps.Size(32, 32)
              : new google.maps.Size(32, 32);

            return (
              segment.location && (
                <MarkerF
                  key={index}
                  position={segment.location}
                  onDragEnd={(event) => handlerMarkerDrag(event, segment)}
                  onClick={() => handleMarkerClickInternal(segment)}
                  draggable={carDragMarker(segment)}
                  options={{
                    icon: {
                      url,
                      scaledSize: scaledSize,
                    },
                    zIndex,
                  }}
                />
              )
            );
          })}
          <PolylineF
            path={gpsPoints}
            options={{
              strokeColor: '#FFD700', // Yellow color
              strokeOpacity: 0.75,
              strokeWeight: 4,
              geodesic: true,
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default GMap;
