import React, { useState, useEffect, useRef, useMemo } from 'react';
import './Player.css';
import { useRecoilState } from 'recoil';
import { videoListState } from '../../atoms/video';
import Button from '../Button/Button';
import { parseCSV } from '../../utils/csvUtils';
import { hmsToSeconds } from '../../utils/timeUtils';
import {
  notification,
  Skeleton,
  Switch,
  InputNumberProps,
  InputNumber,
} from 'antd';
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';

interface PlayerProps {
  selectedVideo: string;
  segmentStartTime: string;
  segmentEndTime: string; // Added this prop
  segmentTitle?: string;
  segmentFileName?: string;
  onSegmentEnd?: (nextSegmentIndex: number) => void;
}

const Player: React.FC<PlayerProps> = ({
  selectedVideo,
  segmentStartTime,
  segmentEndTime,
  segmentTitle,
  onSegmentEnd,
  segmentFileName,
}) => {
  const [notify] = notification.useNotification();
  const videoRefs = useRef<{ [key: string]: HTMLVideoElement | null }>({});
  const [videoSrcs, setVideoSrcs] = useState<{ [fileName: string]: string }>(
    {},
  );
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const csvInputRef = useRef<HTMLInputElement>(null);
  const [playOnlyTimecode, setPlayOnlyTimecode] = useState<boolean>(true);
  const [frameRate, setFrameRate] = useState<number>(30);
  const [framesToRewind, setFramesToRewind] = useState<number>(10);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isPlayingBackwards, setIsPlayingBackwards] = useState(false);
  const [playSpeed, setPlaySpeed] = useState<number>(1);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newVideoSrcs: { [fileName: string]: string } = {};
      Array.from(files).forEach((file) => {
        const fileUrl = URL.createObjectURL(file);
        newVideoSrcs[file.name] = fileUrl;
      });
      setVideoSrcs(newVideoSrcs);
      openVideoNotification(Array.from(files));
    }
  };

  const handlePlayOnlySegments = (checked: boolean) => {
    setPlayOnlyTimecode(checked);
  };

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target?.result as string;
          const importedVideoList = parseCSV(csvContent);
          setVideoList((prevList) => [...prevList, ...importedVideoList]);
          openCsvNotification(Array.from(files));
        };
        reader.readAsText(file);
      });
    }
  };

  useEffect(() => {
    const videoElement = videoRefs.current[selectedVideo];
    if (videoElement && segmentStartTime && segmentEndTime) {
      const segmentStartSeconds = hmsToSeconds(segmentStartTime);
      const segmentEndSeconds = hmsToSeconds(segmentEndTime);

      if (!isNaN(segmentStartSeconds) && !isNaN(segmentEndSeconds)) {
        videoElement.currentTime = segmentStartSeconds;
        videoElement.play().catch((error) => {
          console.error('Error playing video:', error);
        });

        // Add timeupdate event listener to check current play time
        const handleTimeUpdate = () => {
          if (
            videoElement.currentTime >= segmentEndSeconds &&
            playOnlyTimecode
          ) {
            console.log(
              'Reached segment end time, triggering onSegmentEnd.',
              playOnlyTimecode,
            ); // Debugging log
            videoElement.pause(); // Optionally pause the video
            if (onSegmentEnd) {
              const currentIndex = videoList.findIndex((segment) => {
                return (
                  segment.fileName === selectedVideo &&
                  hmsToSeconds(segment.startTime) === segmentStartSeconds &&
                  hmsToSeconds(segment.endTime) === segmentEndSeconds
                );
              });
              onSegmentEnd(currentIndex + 1);
            }
          }
        };

        videoElement.addEventListener('timeupdate', handleTimeUpdate);

        // Cleanup the event listener when the component unmounts or the video changes
        return () => {
          videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        };
      }
    }
  }, [
    selectedVideo,
    segmentStartTime,
    segmentEndTime,
    onSegmentEnd,
    videoList,
  ]);

  const openVideoNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Відео додано',
        description: `Відео ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  const openCsvNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Мета данні додано',
        description: `Мета данні ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  const rewindFrames = (slides: number, frameRate: number) => {
    const video = videoRefs.current[selectedVideo];
    if (video) {
      video.currentTime -= slides / frameRate;
    }
  };

  const forwardFrames = (slides: number, frameRate: number) => {
    const video = videoRefs.current[selectedVideo];
    if (video) {
      video.currentTime += slides / frameRate;
    }
  };

  const onChangeFrameRate: InputNumberProps['onChange'] = (value) => {
    setFrameRate(Number(value));
  };

  const onChangeFramesToRewind: InputNumberProps['onChange'] = (value) => {
    setFramesToRewind(Number(value));
  };

  const onChangePlaySpeed: InputNumberProps['onChange'] = (value) => {
    const video = videoRefs.current[selectedVideo];
    if (video) {
      video.playbackRate = Number(value);
      setPlaySpeed(Number(value));
      if (isPlayingBackwards) {
        pauseVideo();
        playBackward();
      }
    }
  };

  // Function to pause the video
  const pauseVideo = () => {
    const video = videoRefs.current[selectedVideo];
    if (video) {
      setIsPlayingBackwards(false);
      if (intervalId) {
        clearInterval(intervalId); // Clear the interval if it's playing backward
        setIntervalId(null); // Reset interval ID
      }
      video.pause();
    }
  };

  // Function to play the video normally (forward)
  const playForward = () => {
    const video = videoRefs.current[selectedVideo];
    if (video) {
      pauseVideo();
      video.play();
    }
  };

  // Function to simulate playing the video backward
  const playBackward = () => {
    if (videoRefs.current[selectedVideo]) {
      const video = videoRefs.current[selectedVideo];
      if (video) {
        pauseVideo();

        setIsPlayingBackwards(true);
        let currentTime = video.currentTime;
        const iId = setInterval(() => {
          if (currentTime <= 0) {
            clearInterval(iId);
            video.pause();
          } else {
            currentTime -= 0.1 * playSpeed; // Step back by 0.1 * speedRate seconds (adjustable)
            video.currentTime = currentTime; // Update the current time to simulate reverse
          }
        }, 100); // Interval in ms (adjust for smoothness)
        setIntervalId(iId);
      }
    }
  };

  // TODO clarify the flow for the multiple videos
  const visibleVideoFile = useMemo(() => {
    if (Object.keys(videoSrcs).length === 1 || !selectedVideo) {
      return Object.keys(videoSrcs)[0] ?? '';
    }

    return selectedVideo;
  }, [videoSrcs, selectedVideo]);

  return (
    <div className="PlayerMapper_Container">
      <div>
        <h3>
          Video Player:{segmentFileName}
          <span className="Segment_Title">{segmentTitle}</span>
        </h3>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button
          appearance="primary"
          handleClick={() => fileInputRef.current?.click()}
        >
          Choose Videos
        </Button>
        <input
          type="file"
          accept=".csv"
          multiple
          onChange={handleImportCSV}
          style={{ display: 'none' }}
          ref={csvInputRef}
        />
        <Button
          appearance="primary"
          handleClick={() => csvInputRef.current?.click()}
          disabled={JSON.stringify(videoSrcs) === '{}'}
        >
          Import CSVs
        </Button>
        <Switch
          defaultChecked
          onChange={handlePlayOnlySegments}
          checkedChildren="only timecodes"
          unCheckedChildren="full video"
          title="Play only timecodes"
        />
        <div>
          {!Object.keys(videoSrcs).length && (
            <Skeleton paragraph={{ rows: 4 }} />
          )}
          {Object.keys(videoSrcs).map((fileName) => (
            <video
              key={fileName}
              ref={(el) => (videoRefs.current[fileName] = el)}
              width="95%"
              controls
              style={{
                display: visibleVideoFile === fileName ? 'block' : 'none',
              }}
            >
              <source src={videoSrcs[fileName]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}
          {videoRefs.current[selectedVideo] && (
            <div className="controls">
              <button
                onClick={() => rewindFrames(framesToRewind, frameRate)}
                title="Slide Back"
                className="controls-btn"
              >
                <StepBackwardOutlined
                  style={{
                    fontSize: '40px',
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                />
              </button>
              <button
                onClick={playBackward}
                title="Play Backwards"
                className="controls-btn"
              >
                <PlayCircleOutlined
                  rotate={180}
                  style={{
                    fontSize: '40px',
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                />
              </button>
              <button
                onClick={pauseVideo}
                title="Pause Video"
                className="controls-btn"
              >
                <PauseCircleOutlined
                  style={{
                    fontSize: '40px',
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                />
              </button>
              <button
                onClick={playForward}
                title="Play Forward"
                className="controls-btn"
              >
                <PlayCircleOutlined
                  style={{
                    fontSize: '40px',
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                />
              </button>
              <button
                onClick={() => forwardFrames(framesToRewind, frameRate)}
                title="Slide Forward"
                className="controls-btn"
              >
                <StepForwardOutlined
                  style={{
                    fontSize: '40px',
                    color: 'white',
                    backgroundColor: 'black',
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                />
              </button>
              <InputNumber
                min={10}
                max={300}
                defaultValue={30}
                prefix="FR:"
                onChange={onChangeFrameRate}
              />
              <InputNumber
                min={1}
                max={300}
                defaultValue={10}
                prefix="S:"
                onChange={onChangeFramesToRewind}
              />
              <InputNumber
                min={1}
                max={10}
                defaultValue={1}
                prefix="Play Speed:"
                style={{ width: 130, margin: 2 }}
                onChange={onChangePlaySpeed}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Player;
