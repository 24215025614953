/**
 * Color constants for the application
 */
export const colors = {
  // Light theme colors
  light: {
    background: '#ffffff',
    text: '#111827',
    border: '#d1d5db',
    secondaryBackground: '#f3f4f6',
  },

  // Dark theme colors
  dark: {
    background: '#1f2937',
    text: '#f9fafb',
    border: '#374151',
    secondaryBackground: '#111827',
  },

  // Common colors
  common: {
    primary: '#1890ff',
    primaryHover: '#40a9ff',
    success: '#064e3b',
    successHover: '#65d12e',
    warning: '#faad14',
    warningHover: '#ffc53d',
    error: '#f5222d',
    errorHover: '#ff4d4f',
    mask: 'rgba(0, 0, 0, 0.45)',
  },
};
