import { atom } from 'recoil';

export interface LoadedObjects {
  kmz: boolean;
  tiles: boolean;
  tlog: boolean;
}

export const loadedObjectsState = atom<LoadedObjects>({
  key: 'loadedObjectsState',
  default: {
    kmz: false,
    tiles: false,
    tlog: false,
  },
});
