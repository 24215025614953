import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import LoginPage from './pages/LoginPage';
import Viewer from './pages/Viewer';
import Editor from './pages/Editor';
import useAuth from './hooks/useAuth';
import { loadingState } from './atoms/loading';
import { themeState } from './atoms/theme';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import Layout from './layout/Layout';
import { getThemeConfig } from './theme/themeConfig';

import './App.css';

const App: React.FC = () => {
  const { user, loading } = useAuth();
  const isLoading = useRecoilValue(loadingState);
  const theme = useRecoilValue(themeState);

  // Set data-theme attribute for CSS variables
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <ConfigProvider theme={getThemeConfig(theme)}>
      {isLoading && <LoadingOverlay />}
      <Router>
        <Layout>
          <Routes>
            <Route path="/auth" element={<LoginPage />} />
            <Route path="/viewer" element={<Viewer />} />
            <Route path="/editor" element={<Editor />} />
            <Route
              path="/"
              element={user ? <Navigate to="/viewer" replace /> : <LoginPage />}
            />
          </Routes>
        </Layout>
      </Router>
    </ConfigProvider>
  );
};

export default App;
