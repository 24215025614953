import React from 'react';
import { useRecoilValue } from 'recoil';
import { themeState } from '../../atoms/theme';
import { ReactComponent as LogoWhiteIcon } from '../Logo/icons/logo-white.svg';

interface LogoWhiteProps {
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

const LogoWhite: React.FC<LogoWhiteProps> = ({
  id,
  style,
  className,
  onClick,
}) => {
  const theme = useRecoilValue(themeState);

  return (
    <div
      id={id}
      style={{
        ...style,
        backgroundColor: theme === 'dark' ? '#333' : 'white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
      className={className}
      onClick={onClick}
    >
      <LogoWhiteIcon />
    </div>
  );
};

export default LogoWhite;
