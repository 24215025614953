import React from 'react';
import cn from 'classnames';

import Welcome from '../../components/Auth/Welcome';
import MenuLink from '../../components/MenuLink/MenuLink';
import { Logo } from '../../components/Logo/Logo';
import ThemeToggle from '../../components/ThemeToggle/ThemeToggle';

import useAuth from '../../hooks/useAuth';
import styles from './Header.module.css';

const Header: React.FC = () => {
  const { user, role } = useAuth();

  return (
    <header
      className={cn(styles.header, {
        [styles.hideHeader]: !user,
      })}
    >
      <nav className={styles.navBar}>
        <Logo />
        {user && (
          <ul className={styles.navLinks}>
            {(role === 'admin' || role === 'editor') && (
              <li>
                <MenuLink href="/editor" title="Editor" />
              </li>
            )}
            <li>
              <MenuLink href="/viewer" title="Viewer" />
            </li>
          </ul>
        )}
        <div className={styles.rightSection}>
          <ThemeToggle />
          <Welcome user={user} />
        </div>
      </nav>
    </header>
  );
};

export default Header;
