import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Switch, Tooltip } from 'antd';
import { BulbOutlined, BulbFilled } from '@ant-design/icons';
import { themeState, ThemeType } from '../../atoms/theme';
import styles from './ThemeToggle.module.css';

const ThemeToggle: React.FC = () => {
  const [theme, setTheme] = useRecoilState(themeState);

  // Update data-theme attribute on the root element when theme changes
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = (checked: boolean) => {
    const newTheme: ThemeType = checked ? 'dark' : 'light';
    setTheme(newTheme);
  };

  return (
    <div className={styles.themeToggle}>
      <Tooltip
        title={
          theme === 'light' ? 'Switch to dark theme' : 'Switch to light theme'
        }
        placement="bottom"
      >
        <Switch
          checked={theme === 'dark'}
          onChange={toggleTheme}
          checkedChildren={<BulbFilled style={{ color: '#000000' }} />}
          unCheckedChildren={<BulbOutlined style={{ color: '#000000' }} />}
          className={styles.themeSwitch}
        />
      </Tooltip>
    </div>
  );
};

export default ThemeToggle;
