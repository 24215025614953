import { atom, selector } from 'recoil';

export interface Location {
  lat: number;
  lng: number;
}

export interface MapPosition {
  location: Location;
  zoom: number;
}

export type TimeCodeType =
  | ''
  // | 'barriers'
  // | 'civil_car_move'
  // | 'civil_car'
  // | 'civil_person'
  // | 'enemy_vehicle_move'
  // | 'enemy_vehicle'
  // | 'enemy'
  // | 'mines'
  // | 'ok'
  // | 'sp'
  | 'mavic'
  | 'plane'
  | 'fpv';
export const TimeCodeTypeLabels: Record<TimeCodeType, string> = {
  '': '',
  // barriers: 'Barriers',
  // civil_car_move: 'Civil Car Move',
  // civil_car: 'Civil Car',
  // civil_person: 'Civil Person',
  // enemy_vehicle_move: 'Mil Car Move',
  // enemy_vehicle: 'Mil Car Move',
  // enemy: 'Enemy Troops',
  // mines: 'Mines',
  // ok: 'OK',
  // sp: 'Observation Post',
  plane: 'Plane',
  fpv: 'FPV',
  mavic: 'Mavic',
};

export interface VideoInfo {
  fileName: string;
  startTime: string;
  endTime: string;
  title: string;
  location: Location;
  key: string;
  type: TimeCodeType;
}

export const videoInfoState = atom<VideoInfo>({
  key: 'videoInfoState',
  default: {
    fileName: '',
    startTime: '00:00:00',
    endTime: '00:00:00',
    title: '',
    location: { lat: 0, lng: 0 },
    key: 'uuid',
    type: '',
  },
});

export const videoListState = atom<VideoInfo[]>({
  key: 'videoListState',
  default: [],
});

export const videoListFilterByTitle = atom({
  key: 'videoListFilterByTitle',
  default: {
    title: '',
  },
});

export const videoListFilterByFileName = atom({
  key: 'videoListFilterByFileName',
  default: {
    fileNames: [] as string[],
  },
});

export const videoListFilterState = atom<VideoInfo[]>({
  key: 'videoListFilter',
  default: [],
});

export const filteredVideoListState = selector({
  key: 'filteredVideoListState',
  get: ({ get }) => {
    const titleFilter = get(videoListFilterByTitle);
    const fileNameFilter = get(videoListFilterByFileName);
    const list = get(videoListState);

    return list.filter((item) => {
      const matchesTitle =
        !titleFilter.title ||
        item.title?.toLowerCase().includes(titleFilter.title.toLowerCase());

      const matchesFileName =
        fileNameFilter.fileNames.length === 0 ||
        fileNameFilter.fileNames.includes(item.fileName);

      return matchesTitle && matchesFileName;
    });
  },
});
