import { atom } from 'recoil';

// Type of theme
export type ThemeType = 'light' | 'dark';

// Get saved theme from localStorage or use light theme as default
const savedTheme = localStorage.getItem('theme') as ThemeType;
const defaultTheme: ThemeType = savedTheme || 'light';

// Atom for storing current theme
export const themeState = atom<ThemeType>({
  key: 'themeState',
  default: defaultTheme,
});
